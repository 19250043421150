/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 16:05:14
 */
import api from "@/base/utils/request";

//获取用户列表
export const userList = data => {
  return api({
    url: "/admin/admin/user/index",
    method: "post",
    data
  });
}


//创建用户
export const userCreate = data => {
  return api({
    url: "/admin/admin/user/create",
    method: "post",
    data
  });
}


//禁用&激活
export const userUpdateStatus = data => {
  return api({
    url: "/admin/admin/user/updateStatus",
    method: "post",
    data
  });
}


//用户详情
export const userDetail = data => {
  return api({
    url: "/admin/admin/user/detail",
    method: "post",
    data
  });
}

//保存用户详情
export const userSave = data => {
  return api({
    url: "/admin/admin/user/save",
    method: "post",
    data
  });
}
//删除
export const Delete = data => {
  return api({
    url: "/admin/admin/user/del",
    method: "post",
    data
  });
}
//获取架构数据
export const getAuthData = data => {
  return api({
    url: "/admin/admin/user/organizationAuthData",
    method: "post",
    data
  });
}
//保存数据授权
export const saveAuthData = data => {
  return api({
    url: "/admin/admin/user/dataAuth",
    method: "post",
    data
  });
}