<template>
  <div class="user-edit reset-btn-page" v-loading="loading">
    <div class="content">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="85px"
        size="medium"
        class="small-form"
      >
        <el-form-item label="头像：">
          <div class="avatar">
            <single-media-wall
              v-model="form.avatar"
              addText=""
              :width="85"
              :height="85"
              ratio="200:200"
              borderRadius="50%"
              useDefaultAvatar
            ></single-media-wall>
          </div>
          <div class="info">
            <i class="el-icon-info"></i>点击修改头像，上传尺寸比例200*200
          </div>
        </el-form-item>
        <el-form-item label="账号：" prop="account">
          <el-input
            v-model="form.account"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-radio-group v-model="form.gender">
            <el-radio :label="0">保密</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址：" prop="email">
          <el-input
            v-model="form.email"
            placeholder="请输入邮箱地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属平台：" prop="system">
          <el-select v-model="form.system" placeholder="请选择所属平台">
            <el-option
              v-for="sy in systemsOptions"
              :key="sy.system"
              :label="sy.name"
              :value="sy.system"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">激活</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="角色授权：" prop="roles">
          <el-select v-model="form.roles" multiple placeholder="请选择角色授权">
            <el-option
              v-for="avai in availableOptions"
              :key="avai.id"
              :label="avai.name"
              :value="avai.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 数据授权 -->
        <el-form-item label="数据授权：" prop="organization_ids">
          <!-- <el-cascader
            v-model="form.organization_ids"
            :options="AuthOptions"
            :props="{
              emitPath: false,
              label: 'name',
              value: 'id',
              children: 'children',
              multiple: true,
              checkStrictly:true
            }"
          >
          </el-cascader> -->

          <!-- <el-button
            size="mini"
            :type="defaultExpandAll ? 'plain' : 'primary'"
            @click="updateDefaultExpandAll"
          >
            <span class="button-text"
              >一键{{ defaultExpandAll ? '收起' : '展开' }}</span
            >
            <i :class="`el-icon-arrow-${defaultExpandAll ? 'up' : 'down'}`"></i
          ></el-button>
          <el-tree
            v-if="AuthOptions.length"
            :default-expand-all="defaultExpandAll"
            :data="AuthOptions"
            :default-checked-keys="form.organization_ids"
            :show-checkbox="true"
            node-key="id"
            @check="check"
            :props="{
              children: 'children',
              label: 'name',
            }"
          >
          </el-tree> -->

          <el-select v-show="false" multiple v-model="form.organization_ids">
          </el-select>

          <DataAuthorization
            :data="AuthOptions"
            :defaultCheckedKeys="form.organization_ids"
            @check="
              (list) => {
                form.organization_ids = list
              }
            "
          ></DataAuthorization>
        </el-form-item>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button
        size="medium"
        type="primary"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存
      </el-button>
      <el-button size="medium" @click="onCancel('ruleForm')">取消</el-button>
    </FixedActionBar>
  </div>
</template>
<script>
import { availableList, systemsList } from '../api/default'
import { userDetail, userSave, getAuthData } from '../api/user'

import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import DataAuthorization from '@/modules/authority-admin/components/user/DataAuthorization'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { rsaEncrypt } from '@/base/utils/tool'

export default {
  components: {
    FixedActionBar,
    SingleMediaWall,
    DataAuthorization,
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        id: '', //id
        avatar: '', //头像
        account: '', //账号
        password: '', //密码
        nickname: '', //昵称
        gender: '', //性别
        phone: '', //联系方式
        email: '', //邮箱
        roles: '', //角色
        system: '', //所属平台
        status: '', //状态
        organization_ids: [], // 选中的架构权限
      },
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        email: [
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        system: [
          { required: true, message: '请选择所属平台', trigger: 'change' },
        ],
        roles: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        organization_ids: [
          { required: true, message: '请选择权限', trigger: 'change' },
        ],
      },
      systemsOptions: [{ name: '管理后台', system: 'admin' }], //系统平台列表
      availableOptions: [{ id: 1, name: '超级管理员' }], //角色列表
      saveLoading: false, //保存中
      showImageList: false,
      // 架构权限数组
      AuthOptions: [],
    }
  },
  computed: {
    userId() {
      return this.$route.params.id
    },
    pubKey() {
      return this.$store.getters.pubKey
    },
  },
  created() {
    //获取系统平台列表
    this.getSystemsList()
    //获取系统平台可用角色列表
    this.getAvailableList()
    //获取用户详情
    this.getUserDetail()
    if (!this.pubKey) this.$store.dispatch('GET_PUB_KEY')
  },
  methods: {
    // 获取权限架构列表
    getAuthOptions() {
      // id转成数字型
      getAuthData({ admin_user_id: Number(this.userId) })
        .then((res) => {
          // 已经选择的权限id
          this.form.organization_ids = res.data.selected_ids
          // 选择配置
          this.AuthOptions = res.data.list
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //获取系统平台列表
    getSystemsList() {
      systemsList()
        .then((res) => {
          const { data } = res
          this.systemsOptions = data
        })
        .catch((err) => {})
    },
    // 获取系统平台可用角色列表
    getAvailableList() {
      availableList()
        .then((res) => {
          const { data } = res
          this.availableOptions = data
        })
        .catch((err) => {})
    },
    //获取用户详情
    getUserDetail() {
      if (!this.userId) {
        this.$message.warning('没有检测用户id！')
        return
      }
      this.loading = true
      userDetail({ id: this.userId })
        .then((res) => {
          const { data } = res
          console.log(data)
          this.initForm(data)
          // 获取权限架构列表
          this.getAuthOptions()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 初始化表单
    initForm(data) {
      for (let key in this.form) {
        if (key === 'roles') {
          // 保存时不需要对象，只传id，查询时是对象
          let role_ids = data[key].map((item) => item.id)
          this.form[key] = data[key] && data[key].length ? role_ids : []
        } else {
          this.form[key] = data[key] !== null ? data[key] : ''
        }
      }
      this.form.system = 'admin'
    },
    //取消
    onCancel(formName) {
      this.resetForm(formName)
      this.$router.go(-1)
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          let form = JSON.parse(JSON.stringify(this.form))
          let roles = form.roles
          delete form.roles
          // 提交的数据
          const postData = {
            ...form,
            password: rsaEncrypt(form.password),
            role_ids: roles,
          }
          userSave(postData)
            .then((res) => {
              this.saveLoading = false
              this.$message.success(res.msg)
              this.getUserDetail()
            })
            .catch((err) => {
              this.saveLoading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.user-edit {
  .content {
    min-height: 400px;

    .avatar {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
</style>
