<template>
  <div>
    <el-button
      size="mini"
      :type="defaultExpandAll ? 'plain' : 'primary'"
      @click="updateDefaultExpandAll"
    >
      <span class="button-text"
        >一键{{ defaultExpandAll ? '收起' : '展开' }}</span
      >
      <i :class="`el-icon-arrow-${defaultExpandAll ? 'up' : 'down'}`"></i
    ></el-button>
    <el-button size="mini" type="primary" @click="setCheckedNodes">
      一键全选
    </el-button>
    <el-button @click="setUnCheckedNodes" size="mini" type="danger">
      一键全不选
    </el-button>
    <div
      :style="{
        maxHeight: height,
        overflow: height !== 'auto' ? 'auto' : 'hidden',
      }"
      style=""
    >
      <el-tree
        v-if="AuthOptions.length"
        ref="tree"
        :default-expand-all="defaultExpandAll"
        :data="AuthOptions"
        :default-checked-keys="defaultCheckedKeys"
        :show-checkbox="true"
        :check-strictly="true"
        node-key="id"
        @check="check"
        :props="{
          children: 'children',
          label: 'name',
        }"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      defaultExpandAll: false,
      AuthOptions: [],
    }
  },
  watch: {
    data: {
      handler(val) {
        this.AuthOptions = val
        this._initialData = JSON.parse(JSON.stringify(val))
      },
      immediate: true,
    },
    defaultCheckedKeys: {
      handler(val) {
        if (val.length === 0&&this.$refs.tree) {
          this.$refs.tree.setCheckedKeys(val)
        }
      },
    },
  },
  methods: {
    check(node, checked) {
      this.$emit('check', checked.checkedKeys)
    },
    updateDefaultExpandAll() {
      const AuthOptions = this.AuthOptions
      this.AuthOptions = []
      this.defaultExpandAll = !this.defaultExpandAll
      this.$nextTick(() => {
        this.AuthOptions = AuthOptions
      })
    },
    setCheckedNodes() {
      let that = this

      // 设置无关联父子级后，需要递归处理子级的选中哦
      const allNodes = getAllNodes(this._initialData)
      that.$refs.tree.setCheckedNodes(allNodes)

      this.$nextTick(() => {
        this.$emit('check', this.$refs.tree.getCheckedKeys())
      })

      function getAllNodes(arr) {
        let children = []
        arr.forEach(el => {
          if (el.children?.length) {
            children.push(...el.children)
          }
        })
        if (children?.length) return [...arr, ...getAllNodes(children)]

        return arr
      }
    },
    setUnCheckedNodes() {
      this.$refs.tree.setCheckedNodes([])
      this.$nextTick(() => {
        this.$emit('check', this.$refs.tree.getCheckedKeys())
      })
    },
  },
}
</script>

<style lang="scss"></style>
